import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import Paper from '@mui/material/Paper';
import InputAdornments from './components/WorkReport/2_InputAdornment';
import { Box, Button, Backdrop, CircularProgress, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MultiFileUpload from './components/WorkReport/6_FileUpload';
import Swal from 'sweetalert2';
import { axiosPrivate as axios } from '../Api/axios';
import { useAuth } from '@clerk/clerk-react';

const PartRequest = () => {
  const { getToken } = useAuth();
  const [formData, setFormData] = useState({
    wo: '',
    rep: '',
    note: '',
    file: []
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Loading state

  // Handle input changes for text fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle file selection
  const handleFileChange = (files) => {
    setFormData({
      ...formData,
      file: files
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to submit this part request?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit it!',
      });

      if (result.isConfirmed) {
        // Create a FormData object
        const formDataToSend = new FormData();

        // Append each field in formData
        formDataToSend.append('wo', formData.wo);
        formDataToSend.append('rep', formData.rep);
        formDataToSend.append('note', formData.note);

        // Append each file in the file array
        Array.from(formData.file).forEach((file) => {
          formDataToSend.append('file', file);
        });

        setLoading(true); // Show loading indicator

        // Make the POST request using await
        await axios.post('/partrequest', formDataToSend, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${await getToken()}`,
          },
        });

        setLoading(false); // Hide loading indicator

        await Swal.fire(
          'Submitted!',
          'Your part request has been submitted successfully.',
          'success'
        );

        navigate(0); // Refresh or redirect as needed
      }
    } catch (error) {
      setLoading(false); // Hide loading indicator
      console.error('Error submitting the form:', error);
      await Swal.fire(
        'Error!',
        'There was an error submitting the part request. Please try again later.',
        'error'
      );
    }
  };

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Paper elevation={0} sx={{ padding: 1, marginTop: 8 }}>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 9,
              width: '100%',
            }}
          >
            {/* Input Adornments for WO# and Rep */}
            <InputAdornments
              woValue={formData.wo}
              repValue={formData.rep}
              onWoChange={(e) => setFormData({ ...formData, wo: e.target.value })}
              onRepChange={(e) => setFormData({ ...formData, rep: e.target.value })}
            />

            {/* Note field */}
            <TextField
              id="outlined-multiline-static"
              name="note"
              label="Note"
              value={formData.note}
              onChange={handleInputChange}
              multiline
              rows={4}
              fullWidth
            />

            {/* Multi File Upload */}
            <MultiFileUpload onFileSelect={handleFileChange} />

            {/* Submit button */}
            <Button size="large" variant="contained" endIcon={<SendIcon />} type="submit">
              Report
            </Button>
          </Box>
        </form>
      </Paper>
    </>
  );
};

export default PartRequest;
