import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField } from '@mui/material';

export default function OvertimeForm({ onOvertimeChange, onHoursChange, overtimeValue, hoursValue }) {
  const [reason, setReason] = React.useState(overtimeValue || '');
  const [hours, setHours] = React.useState(hoursValue || '');
  const [isFocused, setIsFocused] = React.useState(false); // Track focus

  const handleSelectChange = (event) => {
    const selectedReason = event.target.value;
    setReason(selectedReason);
    
    if (selectedReason === '') {
      // If 'None' is selected, reset the hours and notify the parent
      setHours(''); 
      onHoursChange(''); // Notify parent that hours should be reset
      onOvertimeChange(''); // Notify parent that the reason is reset
    } else {
      onOvertimeChange(selectedReason); // Notify parent with selected reason
    }
  };

  const handleSelectFocus = () => {
    setIsFocused(true); // Set focus when the user clicks on the select box
  };

  const handleSelectBlur = () => {
    setIsFocused(reason !== ''); // Keep the label up only if something is selected
  };

  const handleInput = (event) => {
    const { value } = event.target;

    // Regular expression to allow up to 3 digits and up to 2 decimals, or empty (to allow backspace)
    const regex = /^(\d{0,3})(\.\d{0,2})?$/;

    if (value === '' || (regex.test(value) && parseFloat(value) <= 100)) {
      setHours(value); // Update the local state
      onHoursChange(value); // Notify parent component of change
    }
  };

  React.useEffect(() => {
    if (reason === '') {
      setHours(''); // Ensure hours is cleared if "None" is selected
      onHoursChange(''); // Notify parent that hours should be reset
    }
  }, [reason, onHoursChange]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
      <FormControl sx={{ minWidth: '62%' }}>
        <InputLabel
          id="demo-simple-select-label"
          shrink={isFocused || reason !== ''} // Shrinks when focused or when a value is selected
        >
          Did you do overtime?
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={reason}
          label="Did you do overtime?"
          onChange={handleSelectChange}
          onFocus={handleSelectFocus}
          onBlur={handleSelectBlur}
          sx={{ border: 'gray' }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'More than 3 Work Orders'}>More than 3 Work Orders</MenuItem>
          <MenuItem value={'Difficult Work'}>Difficult Work</MenuItem>
          <MenuItem value={'Longer than 180 miles'}>Longer than 180 miles</MenuItem>
          <MenuItem value={'The same route'}>The same route</MenuItem>
          <MenuItem value={'Requested by Manager'}>Requested by Manager</MenuItem>
        </Select>
      </FormControl>

      {/* Show the Overtime Hours field only when a valid reason is selected */}
      {reason !== '' && (
        <TextField
          id="outlined-number"
          label="Overtime Hours"
          type="number"
          sx={{ minWidth: '38%' }}
          value={hours}
          onChange={handleInput} // Capture changes to hours
        />
      )}
    </Box>
  );
}
