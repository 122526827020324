import * as React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';

function Header(props) {
  const { sections, title, onSectionClick } = props;

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [state, setState] = React.useState({
    left: false,
  });

  return (
    <React.Fragment>
      <Toolbar
        component="nav"
        variant="dense"
        sx={{
          justifyContent: 'space-between',
          overflowX: 'auto',
          gap: 30,
          '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar in WebKit browsers
          scrollbarWidth: 'none',  // Hide scrollbar in Firefox
          minHeight: '35px',
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        {sections.map((section) => (
          <Link
            color="inherit"
            noWrap
            key={section.title}
            variant="body2"
            onClick={() => onSectionClick(section)} // Use onClick instead of href
            sx={{ p: 1, flexShrink: 0, textDecoration: 'none', cursor: 'pointer' }}
          >
            {section.title}
          </Link>
        ))}
      </Toolbar>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string, // url is optional if handled through onSectionClick
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  onSectionClick: PropTypes.func.isRequired, // Ensure onSectionClick is passed as a prop
};

export default Header;
