import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Backdrop,
  CircularProgress,
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Checkbox,
  Collapse,
  IconButton,
  Typography,
  TextField,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Swal from 'sweetalert2';
import { axiosPrivate as axios } from '../Api/axios';
import { useAuth } from '@clerk/clerk-react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

export default function MultiWeekList() {
  const { getToken } = useAuth();
  const [weekData, setWeekData] = useState([]); // State for fetched week data
  const [openWeeks, setOpenWeeks] = useState({}); // State to track which weeks are open
  const [checkedWeeks, setCheckedWeeks] = useState({}); // State to track checkbox data
  const [loading, setLoading] = useState(true); // Loading state
  const [saving, setSaving] = useState(false); // Saving state

  // New States for Editing
  const [editingDay, setEditingDay] = useState(null); // { week: '2024-W47', dayIndex: 2, mode: 'workOrders' | 'regularHours' }
  const [tempWorkOrders, setTempWorkOrders] = useState([]); // Temporary work orders during editing
  const [tempRegularHours, setTempRegularHours] = useState(8); // Temporary regular hours during editing

  // Define days order from Monday to Sunday
  const daysOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  // Utility function to get the ISO week number
  const getISOWeek = (date) => {
    const d = new Date(date);
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7)); // Adjust to nearest Thursday
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    return `${d.getUTCFullYear()}-W${weekNo}`;
  };

  // Process data to categorize by weeks and group days together
  const processData = (data) => {
    if (!data || data.length === 0) return [];

    const weeks = {};

    data.forEach((item) => {
      const week = getISOWeek(item.selectedDate); // Get ISO week

      if (!weeks[week]) {
        weeks[week] = { week, schedule: {} };
      }

      if (!weeks[week].schedule[item.dayOfWeek]) {
        weeks[week].schedule[item.dayOfWeek] = {
          selectedDate: item.selectedDate, // Ensure selectedDate is included
          day: [
            item.dayOfWeek,
            item.dailyRegularHours ?? 8, // Use Nullish Coalescing instead of ||
            0,
          ], // Allow 0 as a valid value
          workOrders: [], // Initialize work orders
        };
      }

      const dayData = weeks[week].schedule[item.dayOfWeek];
      dayData.workOrders.push({
        _id: item._id, // Include the unique identifier
        woNumber: item.wo,
        miles: item.miles,
        hours: item.hours || 0,
        weeklySent: item.weeklySent
      });
      dayData.day[2] += item.hours || 0; // Add overtime hours
    });

    // Convert the weeks object to an array and sort it
    const sortedWeeks = Object.values(weeks).sort((a, b) => a.week.localeCompare(b.week));

    return sortedWeeks.map((week) => ({
      week: week.week,
      schedule: Object.values(week.schedule).sort((a, b) => {
        return daysOrder.indexOf(a.day[0]) - daysOrder.indexOf(b.day[0]);
      }),
    }));
  };

  // Fetch data function
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const token = await getToken();
      const response = await axios.get('/fetchweekly', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); // Fetching data from backend
      const processedData = processData(response.data.data); // Process and set week data
      setWeekData(processedData);
    } catch (error) {
      console.error("Error fetching week data:", error);
      Swal.fire("Error!", "Failed to load data. Please try again later.", "error");
    } finally {
      setLoading(false);
    }
  }, [getToken]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handle toggle for collapse
  const handleCollapseClick = (week) => {
    setOpenWeeks((prevOpen) => ({
      ...prevOpen,
      [week]: !prevOpen[week],
    }));
  };

  // Handle checkbox data collection
  const handleCheckboxChange = (event, week) => {
    setCheckedWeeks((prevChecked) => ({
      ...prevChecked,
      [week]: event.target.checked,
    }));
  };

  // Handler for report submission
  const handleReportSubmit = async () => {
    const selectedWeeks = Object.keys(checkedWeeks).filter((week) => checkedWeeks[week]);
    if (selectedWeeks.length === 0) {
      Swal.fire("No Selection", "Please select at least one week to submit a report.", "info");
      return;
    }

    try {
      setSaving(true);

      // Collect data for selected weeks
      const weeksToSubmit = weekData.filter((weekInfo) => selectedWeeks.includes(weekInfo.week));

      // Prepare payload
      const payload = {
        weeks: weeksToSubmit,
      };

      const response = await axios.post('/weeklyreport', payload, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
      });

      if (response.data.success) {
        Swal.fire('Success!', 'Weekly report submitted successfully.', 'success');
        // Optionally, you can reset the checked weeks or refresh data
        setCheckedWeeks({});
        await fetchData();
      } else {
        Swal.fire('Error!', response.data.message, 'error');
      }
    } catch (error) {
      console.error('Error submitting weekly report:', error);
      Swal.fire('Error!', error?.response?.data?.message || 'Failed to submit weekly report. Please try again later.', 'error');
    } finally {
      setSaving(false);
    }
  };

  // Handler for clicking on day name to edit work orders
  const handleDayClick = (week, dayIndex) => {
    if (editingDay && editingDay.week === week && editingDay.dayIndex === dayIndex && editingDay.mode === 'workOrders') {
      // If already editing work orders, close the edit mode
      setEditingDay(null);
      setTempWorkOrders([]);
    } else {
      // Open edit mode for the selected day work orders
      const selectedDay = weekData.find(w => w.week === week).schedule[dayIndex];
      setEditingDay({ week, dayIndex, mode: 'workOrders' });
      // Deep copy to avoid mutating state directly
      setTempWorkOrders(selectedDay.workOrders.map(wo => ({ ...wo })));
    }
  };

  // Handler for clicking on daily total to edit regular hours
  const handleDailyTotalClick = (week, dayIndex) => {
    if (editingDay && editingDay.week === week && editingDay.dayIndex === dayIndex && editingDay.mode === 'regularHours') {
      // If already editing regular hours, close the edit mode
      setEditingDay(null);
      setTempRegularHours(8);
    } else {
      // Open edit mode for the selected day regular hours
      const selectedDay = weekData.find(w => w.week === week).schedule[dayIndex];
      setEditingDay({ week, dayIndex, mode: 'regularHours' });
      setTempRegularHours(selectedDay.day[1]); // Set to current regular hours
    }
  };

  // Handler for changing work order fields
  const handleWorkOrderChange = (index, field, value) => {
    const updatedWorkOrders = [...tempWorkOrders];
    updatedWorkOrders[index][field] = value;
    setTempWorkOrders(updatedWorkOrders);
  };

  // Handler for adding a work order
  const handleAddWorkOrder = () => {
    setTempWorkOrders([...tempWorkOrders, { woNumber: '', miles: 0, hours: 0 }]);
  };

  // Handler for deleting a work order
  const handleDeleteWorkOrder = (index) => {
    const updatedWorkOrders = tempWorkOrders.filter((_, i) => i !== index);
    setTempWorkOrders(updatedWorkOrders);
  };

  // Handler for saving work orders
  const handleSaveWorkOrders = async () => {
    if (!editingDay) return;

    try {
      setSaving(true);
      const selectedDate = weekData
        .find((w) => w.week === editingDay.week)
        .schedule[editingDay.dayIndex]?.selectedDate; // Find the selected date for the day

      if (!selectedDate) {
        Swal.fire('Error!', 'Selected date is missing. Please try again.', 'error');
        return;
      }

      const payload = {
        selectedDate: new Date(selectedDate).toISOString(), // Ensure ISO format
        workOrders: tempWorkOrders.map((wo) => ({
          _id: wo._id, // Include _id for identification
          woNumber: wo.woNumber,
          miles: wo.miles,
          hours: wo.hours,
        })),
      };

      const response = await axios.post('/editworkorders', payload, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
      });

      if (response.data.success) {
        Swal.fire('Success!', 'Work orders updated successfully.', 'success');
        setEditingDay(null);
        setTempWorkOrders([]);
        await fetchData(); // Re-fetch data to update the UI
      } else {
        Swal.fire('Error!', response.data.message, 'error');
      }
    } catch (error) {
      console.error('Error saving work orders:', error);
      Swal.fire('Error!', error?.response?.data?.message || 'Failed to save work orders. Please try again later.', 'error');
    } finally {
      setSaving(false);
    }
  };

  // Handler for saving regular hours
  const handleSaveRegularHours = async () => {
    if (!editingDay) return;

    if (isNaN(tempRegularHours) || tempRegularHours < 0) {
      Swal.fire("Error!", "Please enter a valid number of regular hours.", "error");
      return;
    }

    try {
      setSaving(true);
      const selectedDate = weekData
        .find((w) => w.week === editingDay.week)
        .schedule[editingDay.dayIndex]?.selectedDate; // Find the selected date for the day

      if (!selectedDate) {
        Swal.fire("Error!", "Selected date is missing. Please try again.", "error");
        return;
      }

      const payload = {
        selectedDate: new Date(selectedDate).toISOString(), // Ensure ISO format
        regularHours: tempRegularHours,
      };

      const response = await axios.post("/editregularhours", payload, {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
        },
      });

      if (response.data.success) {
        Swal.fire("Success!", "Regular hours updated successfully.", "success");
        setEditingDay(null);
        setTempRegularHours(8);
        await fetchData(); // Re-fetch data to update the UI
      } else {
        Swal.fire("Error!", response.data.message, "error");
      }
    } catch (error) {
      console.error("Error saving regular hours:", error);
      Swal.fire("Error!", "Failed to save regular hours. Please try again later.", "error");
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      {/* Loading Backdrop */}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading || saving}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Show "No report found" message if weekData is empty */}
      {weekData.length === 0 && !loading ? (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Typography variant="h6" color="textSecondary">
            No report found
          </Typography>
        </Box>
      ) : (
        <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Monthly Work Schedule
            </ListSubheader>
          }
        >
          {/* Loop through dynamically fetched week data */}
          {weekData.map((weekInfo) => {
            // Calculate totals for each week safely
            const totalRegularHours = (weekInfo.schedule || []).reduce((acc, day) => acc + (day.day[1] || 0), 0);
            const totalOvertimeHours = (weekInfo.schedule || []).reduce((acc, day) => acc + (day.day[2] || 0), 0);
            const totalMiles = (weekInfo.schedule || []).reduce(
              (acc, day) => acc + (day.workOrders || []).reduce((subAcc, order) => subAcc + (order.miles || 0), 0),
              0
            );

            const allWorkOrdersInWeek = weekInfo.schedule.reduce((acc, daySchedule) => {
              return acc.concat(daySchedule.workOrders);
            }, []);

            const allWeeklySent = allWorkOrdersInWeek.every((wo) => wo.weeklySent);

            return (
              <React.Fragment key={weekInfo.week}>
                {/* Week Header with Checkbox for Data Collection */}
                <ListItemButton sx={{ pl: 0, bgcolor: allWeeklySent ? 'lightgray' : 'inherit' }}>
                  <Checkbox
                    checked={!!checkedWeeks[weekInfo.week]}
                    onChange={(e) => handleCheckboxChange(e, weekInfo.week)}
                    sx={{ mr: 2 }}
                  />
                  <ListItemText
                    primary={`${weekInfo.week} (${totalRegularHours} Regular Hours, ${totalOvertimeHours} Overtime Hours, ${totalMiles} Miles)`}
                  />
                  <IconButton onClick={() => handleCollapseClick(weekInfo.week)}>
                    {openWeeks[weekInfo.week] ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </ListItemButton>

                {/* Week Schedule Collapse */}
                <Collapse in={openWeeks[weekInfo.week]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {(weekInfo.schedule || []).map((daySchedule, dayIndex) => {
                      const dayMiles = (daySchedule.workOrders || []).reduce((acc, order) => acc + (order.miles || 0), 0);
                      const isEditingWorkOrders = editingDay && editingDay.week === weekInfo.week && editingDay.dayIndex === dayIndex && editingDay.mode === 'workOrders';
                      const isEditingRegularHours = editingDay && editingDay.week === weekInfo.week && editingDay.dayIndex === dayIndex && editingDay.mode === 'regularHours';

                      return (
                        <React.Fragment key={dayIndex}>
                          {/* Display Day and Work Orders or Edit Mode */}
                          <ListItemButton sx={{ pl: 4 }} onClick={() => handleDayClick(weekInfo.week, dayIndex)}>
                            {isEditingWorkOrders ? (
                              <ListItemText
                                primary={
                                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    {`${daySchedule.day[0]}: Edit Work Orders`}
                                  </Typography>
                                }
                              />
                            ) : (
                              <ListItemText
                                primary={
                                  <Typography variant="body1">
                                    <strong>{daySchedule.day[0]}</strong>
                                    {`: ${(daySchedule.workOrders || [])
                                      .map((order) => `WO${order.woNumber} (${order.miles} mi)`)
                                      .join(', ')}`}
                                  </Typography>
                                }
                              />
                            )}
                          </ListItemButton>

                          {/* Display Regular Hours, Overtime Hours, and Total Miles for the Day */}
                          <ListItemButton
                            sx={{ pl: 6 }}
                            onClick={() => handleDailyTotalClick(weekInfo.week, dayIndex)}
                          >
                            {isEditingRegularHours ? (
                              <ListItemText primary={<Typography variant="body1"><strong>Edit Regular Hours</strong></Typography>} />
                            ) : (
                              <ListItemText
                                primary={`Regular Hours: ${daySchedule.day[1]}, Overtime Hours: ${daySchedule.day[2]}, Total Miles: ${dayMiles}`}
                              />
                            )}
                          </ListItemButton>

                          {/* Edit Mode for Work Orders */}
                          {isEditingWorkOrders && (
                            <Box sx={{ pl: 8, pr: 2, pb: 2 }}>
                              <Button
                                variant="outlined"
                                startIcon={<AddIcon />}
                                onClick={handleAddWorkOrder}
                                sx={{ mb: 10 }}
                              >
                                Add Work Order
                              </Button>
                              {tempWorkOrders.map((wo, index) => (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
                                  <TextField
                                    label="WO Number"
                                    variant="outlined"
                                    size="small"
                                    value={wo.woNumber}
                                    onChange={(e) => handleWorkOrderChange(index, 'woNumber', e.target.value)}
                                    sx={{ mr: 2 }}
                                  />
                                  <TextField
                                    label="Miles"
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    value={wo.miles}
                                    onChange={(e) => handleWorkOrderChange(index, 'miles', Number(e.target.value))}
                                    sx={{ mr: 2 }}
                                  />
                                  <TextField
                                    label="Overtime Hours"
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    value={wo.hours}
                                    onChange={(e) => handleWorkOrderChange(index, 'hours', Number(e.target.value))}
                                    sx={{ mr: 2 }}
                                  />
                                  <IconButton color="error" onClick={() => handleDeleteWorkOrder(index)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              ))}
                              <Box sx={{ display: 'flex', gap: 2 }}>
                                <Button variant="contained" color="primary" onClick={handleSaveWorkOrders}>
                                  Save
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => {
                                    setEditingDay(null);
                                    setTempWorkOrders([]);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Box>
                            </Box>
                          )}

                          {/* Edit Mode for Regular Hours */}
                          {isEditingRegularHours && (
                            <Box sx={{ pl: 8, pr: 2, pb: 2 }}>
                              <TextField
                                label="Regular Hours"
                                variant="outlined"
                                size="small"
                                type="number"
                                value={tempRegularHours}
                                onChange={(e) => setTempRegularHours(Number(e.target.value))}
                                sx={{ mb: 2 }}
                              />
                              <Box sx={{ display: 'flex', gap: 2 }}>
                                <Button variant="contained" color="primary" onClick={handleSaveRegularHours}>
                                  Save
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => {
                                    setEditingDay(null);
                                    setTempRegularHours(8);
                                  }}
                                >
                                  Cancel
                                </Button>
                              </Box>
                            </Box>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </List>
                </Collapse>
              </React.Fragment>
            );
          })}

          {/* Report Button */}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleReportSubmit}
              fullWidth
              size="large"
            >
              Submit Report
            </Button>
          </Box>
        </List>
      )}
    </>
  );
}
