import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const MultiFileUpload = ({ onFileSelect }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles(files);
    onFileSelect(files); // Call the parent function to update formData
  };

  return (
    <Box
      component="form"
      sx={{ display: 'flex', flexDirection: 'column', gap: 10, maxWidth: '100%' }}
      noValidate
      autoComplete="off"
    >
      {/* Custom-styled file upload field for multi-file selection */}
      <TextField
        label="Upload Files"
        InputProps={{
          inputProps: { multiple: true, accept: "image/*, .pdf, .doc, .docx" },
          endAdornment: (
            <Button
              variant="contained"
              component="label"
              sx={{ ml: 2, textTransform: 'none', fontSize: '0.75rem', padding: '2px 4px'}}
            >
              Choose Files
              <input
                type="file"
                multiple
                hidden
                onChange={handleFileChange}
              />
            </Button>
          )
        }}
        value={selectedFiles.length > 0 
          ? Array.from(selectedFiles).map((file) => file.name).join(', ')
          : "No files chosen"}
        variant="outlined"
      />

      {/* Optionally display selected files details */}
      {selectedFiles.length > 0 && (
        <Box mt={2}>
          <p>Selected Files:</p>
          {Array.from(selectedFiles).map((file, index) => (
            <p key={index}>
              {file.name} - {(file.size / 1024).toFixed(2)} KB
            </p>
          ))}
        </Box>
      )}

    </Box>
  );
};

export default MultiFileUpload;
