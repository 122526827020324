import { UserProfile } from '@clerk/clerk-react';

const AuthLogin = () => {
    return (
        <div
            style={{
                display: 'flex',
                minHeight: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#f3f4f6' // optional background color for contrast
            }}
        >
            <UserProfile />
        </div>
    );
};

export default AuthLogin;
