import * as React from 'react';
import Typography from '@mui/material/Typography';
// import Link from '@mui/material/Link';

function SNCheck() {
  return (
    <div style={{ textAlign: 'center', padding: '0px' }}>
      {/* Text and link */}
      <Typography variant="body2" color="text.secondary" align="center" marginTop={'12px'}>
        
          Serial Number Check 
       
      </Typography>
      
      {/* Iframe for warranty check */}
      <iframe
        style={{ maxWidth: '640px', width: '100%', height: '900px', border: 'none', marginTop: '5px' }}
        src="https://so.turboairinc.com/Turbo_WebWarrantySVC/OpenApis/WarrantyRegistrationAndClaim/iframe_statuscheck_tai.html"
        title="Serial Number Check"
      ></iframe>
    </div>
  );
}

export default SNCheck;
