import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { useState, useEffect } from 'react';

export default function ResponsivePickers({ onDateChange, onMilesChange, onDayChange }) {
  const handleInput = (event) => {
    const { value } = event.target;
    const limitedValue = value.slice(0, 3); // Limit to the first 3 digits
    event.target.value = limitedValue;
    onMilesChange(limitedValue);  // Pass the miles value to the parent
  };

  const getLocalTodayDate = () => {
    const today = new Date();
    return today.toLocaleDateString('en-CA'); // This formats it as 'YYYY-MM-DD'
  };

  // Function to get the day of the week
  const getDayOfWeek = (date) => {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayIndex = new Date(date).getDay(); // getDay() returns 0 (Sunday) to 6 (Saturday)
    const nextDayIndex = (dayIndex + 1) % 7;
    return daysOfWeek[nextDayIndex]; // Returns the corresponding day of the week
  };

  const today = getLocalTodayDate();
  const [selectedDate, setSelectedDate] = useState(today);
  const [dayOfWeek, setDayOfWeek] = useState(getDayOfWeek(today));

  // Call onDateChange when the component mounts to pass the default date
  useEffect(() => {
   
    onDateChange(selectedDate); // Pass the default date to the parent on initial render
    onDayChange(dayOfWeek);
  }, [selectedDate, dayOfWeek, onDateChange, onDayChange]);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);
    setDayOfWeek(getDayOfWeek(newDate)); // Update the day of the week
    onDateChange(newDate); 
    onDayChange(getDayOfWeek(newDate))
  };  

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>

      <TextField
        id="outlined-number"
        label={`Work Date -${dayOfWeek}`}
        type="date"
        value={selectedDate}
        sx={{ minWidth: '50%' }}
        slotProps={{
          inputLabel: {
            shrink: true,
          },
        }}
        onChange={handleDateChange}
      />
      <TextField
        id="outlined-number"
        label="Miles"
        type="number"
        sx={{ minWidth: '50%' }}
        slotProps={{
          inputLabel: {
            shrink: true,
          },
        }}
        onInput={handleInput}
      />
    </Box>
  );
}
