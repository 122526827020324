import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PartRequest from './App/2_Main/PartRequest';
import WeeklyReport from './App/2_Main/WeeklyReport';
import SNCheck from './App/2_Main/SNCheck';
import WorkReport from './App/2_Main/App_WorkReport';
import AuthLogin from './App/Auth/Login';
import AuthSignup from './App/Auth/Signup';
import { ClerkProvider } from "@clerk/clerk-react";
import ProtectedRoute from './App/ProtectedRoute';
import Settings from './App/Auth/Settings';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ClerkProvider
    publishableKey='pk_live_Y2xlcmsudHVyYm9haXJpbmMub3JnJA'
    signInUrl="/login" // Redirect unauthenticated users to /login
    afterSignOutUrl="/" // Redirect after sign-out
  >
    <BrowserRouter>
      <Routes>
        {/* Public routes for login and signup */}
        <Route path="/login" element={<AuthLogin />} />
        <Route path="/signup" element={<AuthSignup />} />

        {/* Protected routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <App />
            </ProtectedRoute>
          }
        >
          <Route path="workreport" element={<WorkReport />} />
          <Route path="partrequest" element={<PartRequest />} />
          <Route path="weeklyreport" element={<WeeklyReport />} />
          <Route path="sncheck" element={<SNCheck />} />
          <Route path="settings" element={<Settings />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </ClerkProvider>
);

reportWebVitals();
