import * as React from 'react';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export default function InputAdornments({woValue, repValue, onWoChange, onRepChange}) {
  // const [rep, setRep] = React.useState('');

  // const handleChange = (event) => {
  //   setRep(event.target.value);
  // };

 
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {/* WO# Input Field with Adornment */}
      <TextField
        label="WO#"
        id="outlined-start-adornment"
        InputProps={{
          startAdornment: <InputAdornment position="start">WO-</InputAdornment>,
        }}
        value={woValue}
        onChange={onWoChange}  // Handle change from parent
        variant="outlined"
        sx={{ minWidth: '40%' }}  // Adjust width to fit next to each other
      />

      {/* Rep Dropdown Select with always shrunk label */}
      <FormControl variant="outlined" sx={{ minWidth: '60%' }}>
        <InputLabel shrink id="demo-simple-select-label" sx={{ backgroundColor: 'white', px: 1, transform: 'translate(12px, -9px) scale(0.75)' }} >
          Rep
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={repValue}
          label="Rep"
          onChange={onRepChange}
          
        >
           
          <MenuItem value={'kay@turboairinc.com'}>Report</MenuItem>
          <MenuItem value={'lily@turboairinc.com'}>Lily</MenuItem>
          <MenuItem value={'fanny@turboairinc.com'}>Tiffany</MenuItem>
          <MenuItem value={'dolly@turboairinc.com'}>Dolly</MenuItem>
          <MenuItem value={'judith@turboairinc.com'}>Judith</MenuItem>
          <MenuItem value={'blanca@turboairinc.com'}>Blanca</MenuItem>
          <MenuItem value={'brenda@turboairinc.com'}>Brenda</MenuItem>
          <MenuItem value={'araceli@turboairinc.com'}>Araceli</MenuItem>
          <MenuItem value={'jessica.cruz@turboairinc.com'}>Jessica</MenuItem>
        
        </Select>
      </FormControl>
    </Box>
  );
}
