import { SignUp } from '@clerk/clerk-react'

const AuthSignup = () => {
    return (
        <div
            style={{
                display: 'flex',
                minHeight: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#f3f4f6' // optional background color for contrast
            }}
        >
            <SignUp signInUrl="/login" />
        </div>
    )
}

export default AuthSignup